import type { SVGProps } from 'react';

const SvgGasSlashed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_208_1879)">
      <rect
        x="0.894043"
        y="0.686768"
        width="20.2831"
        height="1"
        transform="rotate(45 0.894043 0.686768)"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.74802 2.56116C2.59202 2.77942 2.5 3.04615 2.5 3.33333V14H9.16667V9H9.18686L6.85353 6.66667H3.83333V3.64647L2.74802 2.56116ZM7.83333 6.23226V3.33333H4.93441L3.61849 2.01742C3.68847 2.00596 3.76024 2 3.83333 2H7.83333C8.56667 2 9.16667 2.6 9.16667 3.33333V7.56559L7.83333 6.23226ZM9.60107 8L11.1667 9.56559V9.33333C11.1667 8.6 10.5667 8 9.83333 8H9.60107ZM11.1667 10.9798V12.3333C11.1667 12.7 11.4667 13 11.8333 13C12.2 13 12.5 12.7 12.5 12.3333V12.3131L13.3042 13.1174C13.0236 13.6425 12.47 14 11.8333 14C10.9133 14 10.1667 13.2533 10.1667 12.3333V9.97981L11.1667 10.9798ZM13.5 11.8989L12.5 10.8989V7.52667C12.2933 7.61333 12.0733 7.66667 11.8333 7.66667C10.9133 7.66667 10.1667 6.92 10.1667 6C10.1667 5.28667 10.6133 4.68667 11.24 4.44667L9.83333 3.04L10.54 2.33333L13.02 4.81333L13.0133 4.82C13.3133 5.12 13.5 5.54 13.5 6V11.8989ZM11.1667 6C11.1667 6.36667 11.4667 6.66667 11.8333 6.66667C12.2 6.66667 12.5 6.36667 12.5 6C12.5 5.63333 12.2 5.33333 11.8333 5.33333C11.4667 5.33333 11.1667 5.63333 11.1667 6Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_208_1879">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgGasSlashed;

import type { SVGProps } from 'react';

const SvgGas = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.0133 5.32L13.02 5.31333L10.54 2.83333L9.83333 3.54L11.24 4.94667C10.6133 5.18667 10.1667 5.78667 10.1667 6.5C10.1667 7.42 10.9133 8.16667 11.8333 8.16667C12.0733 8.16667 12.2933 8.11333 12.5 8.02667V12.8333C12.5 13.2 12.2 13.5 11.8333 13.5C11.4667 13.5 11.1667 13.2 11.1667 12.8333V9.83333C11.1667 9.1 10.5667 8.5 9.83333 8.5H9.16667V3.83333C9.16667 3.1 8.56667 2.5 7.83333 2.5H3.83333C3.1 2.5 2.5 3.1 2.5 3.83333V14.5H9.16667V9.5H10.1667V12.8333C10.1667 13.7533 10.9133 14.5 11.8333 14.5C12.7533 14.5 13.5 13.7533 13.5 12.8333V6.5C13.5 6.04 13.3133 5.62 13.0133 5.32ZM7.83333 7.16667H3.83333V3.83333H7.83333V7.16667ZM11.8333 7.16667C11.4667 7.16667 11.1667 6.86667 11.1667 6.5C11.1667 6.13333 11.4667 5.83333 11.8333 5.83333C12.2 5.83333 12.5 6.13333 12.5 6.5C12.5 6.86667 12.2 7.16667 11.8333 7.16667Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGas;
